<template>
  <div class="text">
    欢迎来到BI系统
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style lang="scss" scoped>
  .text {
    font-size: 40px;
    color: #000;
  }
</style>
